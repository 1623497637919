import type { SharedEnvironment } from '../types';
import { defaultEnv } from './default';

export const sharedProdEnv: SharedEnvironment = {
  ...defaultEnv,
  environment: 'production',
  googleOAuthClientId:
    '966685249037-ujpl6hs5j0v1ijqekmm9ice7mp6tee6a.apps.googleusercontent.com',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee5',
  googlePlacesApiKey: 'AIzaSyBSgHpWUkdCB-qwFSppzkfWLfR7RWC7Ac8',
};
