import type { Environment } from './types';
import { sharedProdEnv, sharedJuniperJpEnv, sharedProdAuEnv } from './shared';

export const env: Environment = {
  ...sharedProdEnv,
  ...sharedJuniperJpEnv,
  ...sharedProdAuEnv,
  adminAppUrl: 'https://admin.myjuniper.jp',
  logoUrl: 'https://assets.myjuniper.co.uk/juniper-logo.svg',
};
